<template>
  <section id="bot-versions">
    <!-- versions table -->
    <vs-table
      v-model="versionSelected"
      pagination
      max-items="5"
      @selected="selectVersion"
      sorted
      :data="botVersions"
      :no-data-text="lang.general.noDataText[languageSelected]"
    >
      <template slot="thead">
        <vs-th sort-key="tag">
          {{ lang.surveyMaker.versions.table.tag[languageSelected] }}
        </vs-th>
        <vs-th sort-key="active">
          {{ lang.surveyMaker.versions.table.status[languageSelected] }}
        </vs-th>
        <vs-th sort-key="_updatedAt">
          {{ lang.surveyMaker.versions.table._updatedAt[languageSelected] }}
        </vs-th>
        <!-- <vs-th>
          {{ lang.surveyMaker.versions.table.edit[languageSelected] }}
        </vs-th> -->
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].tag">
            {{ data[indextr].tag }}
          </vs-td>
          <vs-td :data="data[indextr].active">
            <vs-chip :color="data[indextr].active ? 'success' : ''">
              <div v-if="data[indextr].active">
                {{lang.surveyMaker.production[languageSelected]}}
              </div>
              <div v-else-if="!data[indextr].active && data[indextr].status !== 'qa'">
                {{lang.surveyMaker.development[languageSelected]}}
              </div>
              <div v-else-if="!data[indextr].active && data[indextr].status === 'qa'">
                {{lang.surveyMaker.test[languageSelected]}}
              </div>
            </vs-chip>
          </vs-td>
          <vs-td :data="data[indextr]._updatedAt">
            {{ new Date(data[indextr]._updatedAt) | date }}
          </vs-td>
          <!-- <vs-td>
            <vs-button
              color="warning"
              type="flat"
              icon-pack="feather"
              icon="icon-edit"
              @click.prevent="editVersion(data[indextr])"
            />
          </vs-td> -->
        </vs-tr>
      </template>
    </vs-table>
    <!-- versions table -->

    <!-- actions -->
    <vs-divider />
    <div class="mb-4 mt-4">
      <!-- <vs-button
        v-if="versionSelected"
        icon-pack="feather"
        icon="icon-trash-2"
        type="flat"
        color="danger"
        @click="deleteVersion(versionSelected)"
      >
        {{ lang.surveyMaker.versions.delete[languageSelected] }}
      </vs-button> -->
      <vs-button
        v-show="versionSelected && !versionSelected.active"
        icon-pack="feather"
        icon="icon-arrow-up"
        type="flat"
        :color="'#9ecc38'"
        :gradient-color-secondary="'#3EC9D6'"
        @click="promoteVersion(versionSelected)"
      >
        {{ lang.surveyMaker.versions.promote[languageSelected] }}
      </vs-button>

      <vs-button
        v-show="versionSelected && !versionSelected.active && versionSelected.status !== 'qa'"
        icon-pack="feather"
        icon="icon-arrow-up"
        type="flat"
        :color="'#9ecc38'"
        :gradient-color-secondary="'#3EC9D6'"
        @click="promoteToTestVersion(versionSelected)"
      >
        {{ lang.surveyMaker.versions.promoteToTest[languageSelected] }}
      </vs-button>

      <!-- </div>
    <div class="push-right"> -->
      <vs-button
        type="flat"
        icon="icon-plus"
        icon-pack="feather"
        @click="addVersion"
      >
        {{ lang.surveyMaker.versions.add[languageSelected] }}
      </vs-button>
    </div>
    <!-- actions -->
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'VersionsTable',
  props: {
    selectVersion: {
      type: Function,
      required: true
    },
    addVersion: {
      type: Function,
      required: true
    },
    editVersion: {
      type: Function,
      required: true
    },
    promoteVersion: {
      type: Function,
      required: true
    },
    promoteToTestVersion: {
      type: Function,
      required: true
    },
    deleteVersion: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      versionSelected: null
    }
  },
  components: {
    VsTable: () => import('@/components/vx-table/vsTable'),
    VsTh: () => import('@/components/vx-table/vsTh'),
    VsTr: () => import('@/components/vx-table/vsTr'),
    VsTd: () => import('@/components/vx-table/vsTd')
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('botMaker', ['botVersions']),
    notActiveVersion() {
      if (!this.botVersions) {
        return true
      }
      return !this.botVersions.some(element => element.active)
    }
  },
  methods: {
    // ...mapActions('botMaker', ['CREATE_BOT_VERSION'])
    // select
    // handleSelectedVersion(tr) {
    //   this.$emit('versionSelected', tr)
    // },
    // edit
    // delete
    // promote
    // async promoteVersion() {
    // this.$vs.dialog({
    //   type: 'confirm',
    //   color: 'warning',
    //   title: this.lang.surveyMaker.versions.promotePopup.title[
    //     this.languageSelected
    //   ],
    //   text: (
    //     this.lang.surveyMaker.versions.promotePopup.text[
    //       this.languageSelected
    //     ] || ''
    //   ).replace(
    //     'XXX',
    //     `${this.versionSelected.tag} (${this.versionSelected.status})`
    //   ),
    //   accept: async () => {
    //     try {
    //       this.$vs.loading()
    //       const versionSelectedId = this.versionSelected._id
    //       await services.promoteSurveyVersion(versionSelectedId)
    //       await this.getVersions(this.selected.service)
    //       this.versions.some(element => {
    //         if (element._id === versionSelectedId) {
    //           this.versionSelected = element
    //           this.$router.push({
    //             query: { survey: this.selected._id, version: element._id }
    //           })
    //           this.breadcrumb = [
    //             {
    //               title: this.lang.surveyData.breadcrumb.surveys[
    //                 this.languageSelected
    //               ],
    //               url: '/#/surveys',
    //               active: false,
    //               disabled: true
    //             },
    //             {
    //               title: this.selected.name,
    //               active: false,
    //               disabled: true
    //             },
    //             {
    //               title: element.tag,
    //               active: true
    //             }
    //           ]
    //           return true
    //         }
    //       })
    //       this.stopLoading()
    //       this.$vs.notify({
    //         title: this.lang.surveyMaker.versions.promotePopup.notify.success
    //           .title[this.languageSelected],
    //         text: this.lang.surveyMaker.versions.promotePopup.notify.success
    //           .text[this.languageSelected],
    //         color: 'success',
    //         position: 'top-right'
    //       })
    //     } catch (error) {
    //       console.error(error)
    //       this.stopLoading()
    //       this.$vs.notify({
    //         title: this.lang.surveyMaker.versions.promotePopup.notify.error
    //           .title[this.languageSelected],
    //         text: this.lang.surveyMaker.versions.promotePopup.notify.error
    //           .text[this.languageSelected],
    //         color: 'danger',
    //         position: 'top-right'
    //       })
    //     }
    //   }
    // })
    // }
    // add
    // async saveNewVersion() {
    //   this.$vs.loading({
    //     text: this.lang.botMaker.botEditor.navBar.fork.newVersion.loading[
    //       this.languageSelected
    //     ],
    //     textAfter: true
    //   })
    //   try {
    //     const payload = {
    //       serviceId: this.$route.query.serviceId || null,
    //       forkingBot: this.forkingBot,
    //       tag: this.forkingTag
    //     }
    //     await this.CREATE_BOT_VERSION(payload)
    //     this.$vs.loading.close()
    //     this.newVersion.tag = ''
    //     this.newVersion.cantSurvey = 0
    //     this.newVersion.production = false
    //     this.newVersion.popup = false
    //     this.$refs.newVersionFormWizard.reset()
    //     this.newVersion.popup = false
    //     this.$vs.notify({
    //       color: 'success',
    //       text: this.lang.botMaker.botEditor.navBar.fork.messages.createVersion
    //         .success[this.languageSelected]
    //     })
    //   } catch (error) {
    //     this.$vs.notify({
    //       color: 'danger',
    //       text: this.lang.botMaker.botEditor.navBar.fork.messages.createVersion
    //         .success[this.languageSelected]
    //     })
    //     console.error(error)
    //     this.newVersion.popup = false
    //     this.$vs.loading.close()
    //   }
    // }
  }
}
</script>
